<template>
  <aside id="sidebar" class="fixed z-20 h-full top-0 left-0 pt-16 flex lg:flex flex-shrink-0 flex-col w-64 transition-width duration-75 sp-sidebar" aria-label="Sidebar">
    <div class="relative flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white pt-0">
      <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
        <div class="flex-1 bg-white divide-y space-y-1">
          <ul class="space-y-2 pb-2">
            <li>
              <div class="avatar flex justify-center items-center flex-col mb-5">
                <div class="relative rounded-full text-xl text-white">
                  <img :src="image" class="rounded-full" />
                </div>
                <h3 class="pt-4">{{ name }}</h3>
                <rating-bar :rating="rating" />
              </div>
            </li>
            <li class="menu-item">
              <router-link :to="{ name: 'consultant_private_profile' }" class="text-base text-gray-900 font-normal flex items-center p-4 hover:bg-gray-100 group">
                <font-awesome-icon :icon="['fas', 'house-flag']" />
                <span class="ml-3">Account Summary</span>
              </router-link>
            </li>

            <li class="menu-item">
              <router-link :to="{ name: 'consultant_update' }" class="text-base text-gray-900 font-normal hover:bg-gray-100 flex items-center p-4 group">
                <font-awesome-icon :icon="['fas', 'id-card']" />
                <span class="ml-3 flex-1 whitespace-nowrap">My Profile</span>
              </router-link>
            </li>

            <li class="menu-item">
              <router-link :to="{ name: '404_page' }" class="text-base text-gray-900 font-normal hover:bg-gray-100 flex items-center p-4 group">
                <font-awesome-icon :icon="['fas', 'calendar-days']" />
                <span class="ml-3 flex-1 whitespace-nowrap">Meetings</span>
              </router-link>
            </li>

            <li class="menu-item">
              <router-link :to="{ name: 'consultant_messages' }" class="text-base text-gray-900 font-normal hover:bg-gray-100 flex items-center p-4 group">
                <font-awesome-icon :icon="['fas', 'message']" />
                <span class="ml-3 flex-1 whitespace-nowrap">Messages</span>
              </router-link>
            </li>

            <li class="menu-item">
              <router-link :to="{ name: '404_page' }" class="text-base text-gray-900 font-normal hover:bg-gray-100 flex items-center p-4 group">
                <font-awesome-icon :icon="['fas', 'inbox']" />
                <span class="ml-3 flex-1 whitespace-nowrap">Inbox</span>
              </router-link>
            </li>

            <li class="menu-item">
              <router-link :to="{ name: '404_page' }" class="text-base text-gray-900 font-normal hover:bg-gray-100 flex items-center p-4 group">
                <font-awesome-icon :icon="['fas', 'envelope-circle-check']" />
                <span class="ml-3 flex-1 whitespace-nowrap">Sent</span>
              </router-link>
            </li>

            <li class="menu-item">
              <router-link :to="{ name: '404_page' }" class="text-base text-gray-900 font-normal hover:bg-gray-100 flex items-center p-4 group">
                <font-awesome-icon :icon="['fas', 'pen-ruler']" />
                <span class="ml-3 flex-1 whitespace-nowrap">Drafts</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import RatingBar from '@/components/RatingBar.vue';
export default {
  name: 'sidebar',
  props: {
    name: {
      type: String,
      default: 'John Doe',
    },
    rating: {
      type: Number,
      default: 0,
    },
    image: {
      type: String,
      default: 'https://avatars0.githubusercontent.com/u/527058?s=460&v=4',
    },
  },
  components: {
    'rating-bar': RatingBar,
  },
  methods: {
    hello() {
      console.log('hello');
    },
  },
};
</script>

<style scoped>
.router-link-exact-active {
  border-right: 3px solid #00d1b2;
  background-color: #cccccc;
}
</style>
