<template>
  <div class="service-provider user-profile mx-auto" style="z-index: 400">
    <div class="flex overflow-hidden bg-white sp-profile-wrap">
      <sidebar :rating="rating" :name="consultant == null ? null : consultant.name" :image="image" />
      <div class="bg-gray-900 opacity-50 hidden fixed inset-0 z-10" id="sidebarBackdrop"></div>
      <div id="main-content" class="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64">
        <main>
          <router-view @update:dp="onDpUpdate" />
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import consultantSideBar from '@/views/consultant-pages/sidebar.vue';

export default {
  name: 'consultant_private',
  components: {
    sidebar: consultantSideBar,
  },
  data() {
    return {
      image: null,
      rating: 5,
    };
  },
  computed: {
    consultant() {
      return this.$store.state.user;
    },
  },
  methods: {
    onDpUpdate() {
      this.$s3.getDpUrl(this.consultant.id).then((url) => {
        this.image = url;
        this.$emit('update:dpUrl', url);
      });
    },
  },
  mounted() {
    this.$s3.getDpUrl(this.consultant.id).then((url) => (this.image = url));
    this.rating = 5;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// @import "~@/scss/variables.scss";
</style>
